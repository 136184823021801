<template>
    <v-card class="basic-tile elevation-0" @click="clicked()">
        <div class="basic-tile-inner">
            <div class="image-container flex-shrink-1">
                <template v-if="hasImage">
                    <v-img class="tile-image rounded-lg grey" :src="imageUrl">
                        <template v-slot:placeholder>
                            <v-row class="fill-height grey ma-0" align="center" justify="center">
                                <v-progress-circular
                                    :width="2"
                                    :size="20"
                                    color="dark"
                                    indeterminate
                                ></v-progress-circular>
                            </v-row>
                        </template>
                    </v-img>
                </template>

                <template v-else>
                    <v-row class="rounded-lg fill-height full-width grey ma-0" align="center" justify="center">
                        <v-icon color="dark-grey" x-large>mdi-image-outline</v-icon>
                    </v-row>
                </template>
            </div>

            <div class="light pa-3 full-width d-flex flex-column align-start flex-grow-1">
                <span class="small--text text-overflow">
                    <b class="mb-1">{{ item.title }}</b>
                </span>

                <slot name="default" />
            </div>
        </div>
    </v-card>
</template>

<script>
export default {
    name: "basic-tile",

    props: {
        item: {
            type: Object,
            default() {
                return {};
            },
        },
    },

    components: {},

    data() {
        return {};
    },

    computed: {
        hasImage() {
            let image = this.item?.data?.image;

            return image?._id || image;
        },
        imageUrl() {
            return this.$fluro.asset.imageUrl(this.item?.data?.image) || "";
        },
    },

    methods: {
        clicked() {
            this.$emit("click", this.entry);
        },
    },

    async mounted() {},
};
</script>

<style lang="scss">
.basic-tile {
    width: 100%;
    padding-top: 100%;
    position: relative;
    border-radius: 7px;
    overflow: hidden;

    .basic-tile-inner {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .image-container {
            // padding-bottom: 100%;
            height: 100%;
            position: relative;
            width: 100%;
            overflow: hidden;

            .tile-image,
            .row {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
            }
        }
    }

    .basic-tag {
        font-size: 10px;
        padding: 3px 5px;
        border-radius: 4px;
        position: relative;
        background-color: transparent !important;
        background: transparent !important;

        &:before {
            content: "";
            opacity: 0.1;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }

        &.success {
            &:before {
                background-color: var(--v-success-base);
            }

            color: var(--v-dark-base);
        }

        &.error {
            &:before {
                background-color: var(--v-error-base);
            }

            color: var(--v-dark-base);
        }

        &.warning {
            &:before {
                background-color: var(--v-warning-base) !important;
            }

            color: var(--v-dark-base) !important;
        }

        &.pending {
            color: var(--v-dark-base);

            &:before {
                background-color: var(--v-info-base);
            }
        }

        &.grey {
            background-color: var(--v-grey-base) !important;

            color: var(--v-dark-base);
        }
    }
}
</style>
